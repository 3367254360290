import React, { useState, useEffect } from "react";
import axios from "axios";
import { navigate } from "gatsby";
import PrimaryButton from "../Button/PrimaryButton";
import {
  Form,
  FormTitle,
  FormSubTitle,
  FormRow,
  FormCol,
  FormTwoCol,
  FormAction,
} from "../Form";
import StateSelect from "../StateSelect";

const RequestForm = ({ location, thanksURL, productData }) => {
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (typeof window !== "undefined") {
      const searchParams = new URLSearchParams(window.location.search);
      if (
        searchParams.get("utm_source") != "" &&
        searchParams.get("utm_source") != null
      ) {
        sessionStorage.setItem("utm_source", searchParams.get("utm_source"));
        sessionStorage.setItem("utm_medium", searchParams.get("utm_medium"));
        sessionStorage.setItem(
          "utm_campaign",
          searchParams.get("utm_campaign")
        );
        sessionStorage.setItem("utm_term", searchParams.get("utm_term"));
        sessionStorage.setItem("utm_content", searchParams.get("utm_content"));
      }
    }
  }, []);

  const handleSubmit = (e) => {
    let utm_source_val = "Website";
    let utm_medium_val = "";
    let utm_campaign_val = "";
    let utm_term_val = "";
    let utm_content_val = "";
    if (
      sessionStorage.getItem("utm_source") != "" &&
      sessionStorage.getItem("utm_source") != null
    ) {
      utm_source_val = sessionStorage.getItem("utm_source");
      utm_medium_val = sessionStorage.getItem("utm_medium");
      utm_campaign_val = sessionStorage.getItem("utm_campaign");
      utm_term_val = sessionStorage.getItem("utm_term");
      utm_content_val = sessionStorage.getItem("utm_content");
    }
    e.preventDefault();
    if (
      firstname !== "" &&
      lastname !== "" &&
      phone !== "" &&
      email !== "" &&
      zipcode !== "" &&
      message !== ""
    ) {
      let formName = "get-quote-form";
      if (location.pathname === "/rent-to-own-metal-buildings/") {
        formName = "rto-quote-form";
      } else if (location.pathname === "/financing/") {
        formName = "financing-quote-form";
      } else if (location.pathname === "/contact-us/") {
        formName = "contact-us-form";
      } else {
        formName = "get-quote-form";

        if (thanksURL === "/thank-you-get-in-touch/") {
          formName = "get-in-touch-form";
        }
        if (thanksURL === "/thank-you-product-quote/") {
          formName = "product-quote-form";
        }
      }
      const data = {
        source: location.origin,
        form: formName,
        page: location.pathname,
        first_name: firstname,
        last_name: lastname,
        phone_no: phone,
        email: email,
        zipcode: zipcode,
        comment: `
          - Product Name: ${productData.title}
          - SKU: ${productData.skuNumber}
          - Product Price: $${productData.price.toLocaleString("en-US")}
          - Comment: ${message}
        `,
        utm_medium: utm_medium_val,
        utm_term: utm_term_val,
        utm_campaign: utm_campaign_val,
        utm_content: utm_content_val,
        lead_source: utm_source_val,
      };

      //console.log(data);
      axios({
        method: "post",
        url: "https://crm.senseicrm.com/api/v1/create-lead",
        data: data,
        headers: { Authorization: "Bearer " + process.env.GATSBY_API_TOKEN },
      })
        .then((res) => {
          //console.log("dataLayer start")
          if (typeof dataLayer !== 'undefined') {
            window.dataLayer.push({
              'event': 'form_submit',
              'formName': 'website_forms',
              'enhanced_conversion_data': {
                'email': email,
                'phone_number': phone
              }
            });
          }
          //console.log("dataLayer end")
          navigate(thanksURL);
          if (typeof window !== `undefined`) {
            document.body.classList.remove("modal-open");
          }
        })
        .catch((error) => {
          alert(error);
        });
    }
  };
  return (
    <>
      <FormTitle className="form-title">REQUEST BUILDING INFO</FormTitle>
      <FormSubTitle className="form-subtitle">
        {productData.title}{" "}
      </FormSubTitle>
      <form onSubmit={handleSubmit}>
        <Form className="form">
          <FormRow className="form-row">
            <FormTwoCol className="form-coltwo">
              <div className="form-group">
                <input
                  type="text"
                  id="firstname"
                  className="form-control"
                  placeholder="First Name"
                  pattern="^(?!\s*$)[A-Za-z\s]+$"
                  required
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
            </FormTwoCol>
            <FormTwoCol className="form-coltwo">
              <div className="form-group">
                <input
                  type="text"
                  id="lastname"
                  className="form-control"
                  placeholder="Last Name"
                  pattern="^(?!\s*$)[A-Za-z\s]+$"
                  required
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
            </FormTwoCol>

            <FormTwoCol className="form-coltwo">
              <div className="form-group">
                <input
                  type="email"
                  id="email"
                  className="form-control"
                  placeholder="Email ID"
                  required
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </FormTwoCol>
            <FormTwoCol className="form-coltwo">
              <div className="form-group">
                <input
                  type="tel"
                  id="phone_no"
                  className="form-control"
                  placeholder="Phone Number"
                  required
                  pattern="[0-9]{10}"
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
            </FormTwoCol>
            <FormTwoCol className="form-coltwo">
              <div className="form-group">
                <input
                  type="text"
                  id="zip_code"
                  pattern="[A-Za-z0-9]+$"
                  className="form-control"
                  placeholder="Zip Code"
                  required
                  onChange={(e) => setZipcode(e.target.value)}
                />
              </div>
            </FormTwoCol>
            <FormTwoCol className="form-coltwo">
              <div className="form-group form-group-select">
                <StateSelect id="state" />
              </div>
            </FormTwoCol>
            <FormCol>
              <div className="form-group">
                <textarea
                  name="review"
                  rows="3"
                  type="text"
                  id="message"
                  className="form-control"
                  placeholder="Enter Your Message"
                  required
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
              </div>
            </FormCol>
          </FormRow>
          <FormAction className="form-action">
            <button type="submit" aria-label="button">
              <PrimaryButton text="Send Message" />
            </button>
          </FormAction>
        </Form>
      </form>
    </>
  );
};

export default RequestForm;
